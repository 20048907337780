/* GENERAL */

.red-bold-underlined {
	color: red !important;
	font-weight: bold !important;
	text-decoration: underline !important;
}

.pb-6 {
	padding-bottom: 4rem !important;
}


.py-6 {
	padding-top: 4rem !important;
	padding-bottom: 4rem !important
}

.w-md-75 {
	width: 75% !important;
}

.no-select {
	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none; /* Safari */
	-khtml-user-select: none; /* Konqueror HTML */
	-moz-user-select: none; /* Old versions of Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
}

.shadowbase {
	-webkit-box-shadow: 0px 7px 20px -6px rgba(0,0,0,0.25);
	-moz-box-shadow: 0px 7px 20px -6px rgba(0,0,0,0.25);
	box-shadow: 0px 7px 20px -6px rgba(0,0,0,0.25);
}

.fs-125rem {
	font-size: 1.25rem;
}

.fs-150rem {
	font-size: 1.5rem;
}

.fs-175rem {
	font-size: 1.75rem;
}

.fs-200rem {
	font-size: 2rem;
}

.flex-center {
	display: flex;
	align-items: center;
	justify-content: center;
}

.span-link {
	color: #0066ff;
	cursor: pointer;
}

.span-link:hover {
	color: #0a58ca;
}

// take up all space, to prevent CLS issues
.vue-comp-container[v-cloak] {
	height: 100vh; /* Set the height to 100% of the viewport height */
	border: 0px solid red;
}

// take up all space, to prevent CLS issues
.vue-comp-container:not([v-cloak]) {
	height: auto;
}

/* GENERAL */




/* DECORATIONS */

.nav-item.nav-item-divider {
	display: flex;
	align-items: center;
}

.vertical-divider {
	border: 1px solid rgb(51, 65, 85);
	height: 20px;
	margin: 0 0.5rem;
}

@media screen and (min-width: 992px) {
	.purple-bullets li a.nav-link::before {
		content: "\A";
		width: 5px;
		height: 5px;
		border-radius: 50%;
		background: rgb(67,56,202);
		display: block;
		vertical-align: middle;
		position: relative;
		left: -13px;
		top: 14px;
	}

	.purple-bullets li {
		margin-left: 15px;
	}

	.purple-bullets li:first-child {
		margin-left: 10px;
	}
}

/* DECORATIONS */




/* KEY FRAMES */

.glow-red {
	animation: kf-glow-red 4s;
	animation-timing-function: ease-in-out;
}

@keyframes kf-glow-red {
	from {
		color: #000000;
	}

	6.25% {
		color: #ff0000;
	}

	56.25% {
		color: #ff0000;
	}

	to {
		color: #000000;
	}
}

/* KEY FRAMES */