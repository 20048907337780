$gray-50: rgb(248,250,252);
$gray-100: rgb(241,245,249);
$gray-200: rgb(226,232,240);
$gray-300: rgb(203,213,225);
$gray-400: rgb(148,163,184);
$gray-500: rgb(100,116,139);
$gray-600: rgb(71,85,105);
$gray-700: rgb(51,65,85);
$gray-800: rgb(30,41,59);
$gray-900: rgb(15,23,42);

$green-50: rgb(240,253,250);
$green-100: rgb(204,251,241);
$green-200: rgb(153,246,228);
$green-300: rgb(94,234,212);
$green-400: rgb(45,212,191);
$green-500: rgb(20,184,166);
$green-600: rgb(13,148,136);
$green-700: rgb(15,118,110);
$green-800: rgb(17,94,89);
$green-900: rgb(19,78,74);

$blue-50: rgb(238,242,255);
$blue-100: rgb(224,231,255);
$blue-200: rgb(199,210,254);
$blue-300: rgb(165,180,252);
$blue-400: rgb(129,140,248);
$blue-500: rgb(99,102,241);
$blue-600: rgb(79,70,229);
$blue-700: rgb(67,56,202);
$blue-800: rgb(55,48,163);
$blue-900: rgb(49,46,129);


// main colors
$main-purple-medium: rgb(67,56,202);
$main-font-color: rgb(30,41,59);

// backgrounds
$bg-purple-light: rgb(224,231,255);
$bg-gray-light: rgb(226,232,240);

// outlines
$outline-purple-light: rgba(67,56,202,0.25);
