.accordion {
	--bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27rgb%2830, 41, 59%29%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e");
	--bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27rgb%2867, 56, 202%29%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e");

	& .accordion-button {
		color: rgb(30, 41, 59);
		background-color: rgb(255, 255, 255);

		&:not(.collapsed) {
			color: rgb(67, 56, 202);
			background-color: rgb(255, 255, 255);
			box-shadow: inset 0 calc(-1*1px) 0 rgb(203, 213, 225);
		}

		&:not(.collapsed)::after {
			background-image: var(--bs-accordion-btn-active-icon);
		}

		&:after {
			background-image: var(--bs-accordion-btn-icon);
		}

		&:focus {
			border-color: rgb(161, 156, 229);
			box-shadow: 0 0 0 0.25rem rgba(67, 56, 202, 0.25);
		}
	}

	& .accordion-item {
		color: rgb(30, 41, 59);
		background-color: rgb(255, 255, 255);
	}
}