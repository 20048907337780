// CATEGORY CARDS

.category-card {
	transition: transform 300ms ease-in-out;

	& i {
		width: 1.5rem;
		height: 1.5rem;
	}

	& .category-image {
		position: relative;
	}

	& .card-body {
		color: rgb(51, 65, 85);
	}

	@media(min-width: 768px) {
		&:hover {
			transform: scale(1.025);
		}
	}
}




// PRODUCT CARDS

.product-card {
	transition: transform 200ms ease-in-out;

	& .product-card-title {
		text-overflow: ellipsis;
		overflow: hidden;

		& span {
			white-space: nowrap;
		}
	}

	& .badge {
		border-radius: 0.5rem;
		font-size: 0.875rem;
	}

	& .regular-price {
		background: rgb(224, 231, 255);
		color: rgb(67, 56, 202);
	}

	& .discount-price {
		background: rgb(153, 246, 228);
		color: rgb(30, 41, 59);
	}

	& .sale {
		background: #13795b;
		color: #ffffff;
	}

	& .sale-timer {
		color: #13795b;
		font-weight: 600;
		margin-left: 0.5rem;
	}

	@media(min-width: 768px) {
		&:hover {
			transform: scale(1.025);
		}
	}
}




// STEP CARDS

.step-card {
	color:rgb(51, 65, 85);
}




// ALL CARDS

.category-card, .product-card, .step-card, .cta-card {
	border: 1px solid rgb(226, 232, 240);
	border-radius: 0.5rem;

	& .cover-center {
		width: 100%;
		height: 100%;
		object-fit: cover;
		position: absolute;
		top: 0;
		left: 0;
	}

	& .bg-image-top {
		@extend .rounded-top;
		@extend .cover-center;
	}

	& .bg-image-start {
		@extend .rounded-start;
		@extend .cover-center;
	}

	& .bg-image-end {
		@extend .rounded-end;
		@extend .cover-center;
	}

	& .rounded {
		border-radius: 0.5rem !important;
	}

	& .rounded-circle {
		border-radius: 50% !important;
	}

	& .rounded-top {
		border-top-left-radius: 0.5rem !important;
		border-top-right-radius: 0.5rem !important;
	}

	& .rounded-start {
		border-bottom-left-radius: 0.5rem !important;
		border-top-left-radius: 0.5rem !important;
	}

	& .rounded-end {
		border-top-right-radius: 0.5rem !important;
		border-bottom-right-radius: 0.5rem !important;
	}

	& .ratio-3x2 {
		padding-top: 66.6666666667%;
	}

	& .badges {
		display: flex;
		flex-wrap: wrap;
		gap: 12px;
		color: rgb(67, 56, 202);
		padding-top: 1px;
	}
}