// BROWSER DEFAULT OVERRIDES

html {
	position: relative;
	min-height: 100%;
}


// BOOTSTRAP OVERRIDES

/* override bootstrap 5's 'smooth' setting, which makes middle-mouse-drag-scrolling very slow */
@media (prefers-reduced-motion: no-preference) {
	:root {
		scroll-behavior: auto !important;
	}
}

body {
	font-family: 'Euclid Circular B', system-ui, -apple-system, 'Helvetica Neue', Arial, sans-serif;
	color: rgb(30, 41, 59);
	-webkit-tap-highlight-color: rgba(30, 41, 59, 0);
}

.dropdown-toggle::after {
	margin-left: 0.5em;
	vertical-align: 0.1em;
	color: rgb(67,56,202);
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
	font-weight: 600;
}

a {
	color: #0066ff;
	text-decoration: none;
	background-color: transparent;
}

button {
	font-weight: 600;
}

.bg-light {
	background-color: rgb(226, 232, 240) !important;
}

.text-primary {
	color: rgb(67, 56, 202) !important;
}

.bg-secondary {
	background-color: rgb(224, 231, 255) !important;
}

.text-dark {
	color: rgb(51, 65, 85) !important;
}

.container, .container-fluid {
	width: 100%;
	padding-right: calc(2rem * 0.5);
	padding-left: calc(2rem * 0.5);
	margin-right: auto;
	margin-left: auto;
}

@media(min-width: 768px) {
	.container, .container-fluid {
		padding-right: 1.5rem;
		padding-left: 1.5rem;
	}
}