.navbar {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;
	padding: 1rem 0;

	& > .container, > .container-fluid {
		display: flex;
		flex-wrap: inherit;
		align-items: center;
		justify-content: space-between;

		& .navbar-brand {
			padding-top: 0.3125rem;
			padding-bottom: 0.3125rem;
			margin-right: 1rem;
			font-size: 1.25rem;
			color: $gray-900;
			text-decoration: none;
			white-space: nowrap;

			&:hover {
				color: $gray-900;
			}

			& img {
				height: 2.5rem;
			}
		}

		& .toggler {
			color: $gray-800;
			outline: 0;
			box-shadow: none;

			& .icon-menu:before {
				content: "\e800";
			}
		}

		& .toggler[aria-expanded=true] .icon-menu:before {
			content: "\e815";
		}

		& .navbar-collapse {
			flex-basis: 100%;
			flex-grow: 1;
			align-items: center;

			& .navbar-nav {
				color: $gray-500;
				display: flex;
				flex-direction: column;
				padding-left: 0;
				margin-bottom: 0;
				list-style: none;

				& .nav-link {
					display: block;
					padding: 0.5rem 0;
					color: $gray-500;
					text-decoration: none;
					transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out;

					&:hover {
						color: $gray-700;
					}

					&:focus {
						color: $gray-700;
					}

					&:focus:not(:hover) {
						color: $gray-500;
					}

					&:active {
						color: $gray-700;
					}
				}
			}
		}
	}

	@media(min-width: 992px) {
		&.navbar-expand-lg > .container,
		&.navbar-expand-lg > .container-fluid {

			& .navbar-nav {
				flex-direction: row;
			}

			& .navbar-collapse {
				flex-basis: auto;

				& .navbar-nav .nav-link {
					padding-right: 0.5rem;
					padding-left: 0.5rem;
				}
			}
		}
	}
}