@mixin btn-general($color, $color-active, $background, $background-dark, $background-active, $border-color, $border-color-dark) {
	color: $color;
	background: $background;
	border-color: $border-color;
	font-weight: 600;
	border-radius: 0.5rem;
	padding: 0.375rem 1rem;

	&:hover {
		background: $background-dark;
		border-color: $border-color-dark;
		color: $color;
	}

	&:focus {
		background: $background-dark;
		border-color: $border-color-dark;
		outline: none !important;
		box-shadow: none !important;
	}

	&:focus:not(:hover) {
		background: $background;
		border-color: $border-color;
	}

	&:active {
		background: $background-active;
		border-color: $border-color-dark;
		color: $color-active;
	}

	&:disabled {
		background: $background;
		border-color: $border-color;
	}
}

.btn-primary {
	$color: rgb(255, 255, 255);
	$color-active: rgb(255, 255, 255);
	$background: rgb(67, 56, 202);
	$background-dark: rgb(57, 48, 172);
	$background-active: rgb(54, 45, 162);
	$border-color: rgb(67, 56, 202);
	$border-color-dark: rgb(54, 45, 162);
	@include btn-general($color, $color-active, $background, $background-dark, $background-active, $border-color, $border-color-dark);
}

.btn-light {
	$color: rgb(30, 41, 59);
	$color-active: rgb(30, 41, 59);
	$background: rgb(226, 232, 240);
	$background-dark: rgb(192, 197, 204);
	$background-active: rgb(181, 186, 192);
	$border-color: rgb(226, 232, 240);
	$border-color-dark: rgb(181, 186, 192);
	@include btn-general($color, $color-active, $background, $background-dark, $background-active, $border-color, $border-color-dark);
}

.btn-green {
	$color: white;
	$color-active: white;
	$background: rgb(56, 182, 67);
	$background-dark: rgb(48, 152, 57);
	$background-active: rgb(45, 142, 54);
	$border-color: rgb(56, 182, 67);
	$border-color-dark: rgb(45, 162, 54);
	@include btn-general($color, $color-active, $background, $background-dark, $background-active, $border-color, $border-color-dark);
}