.hero-search {
	& h1 .display-2, .h1 .display-2 {
		height: calc(1.575rem + 3.9vw);
	}

	& form {
		margin-block-end: 0;
	}

	& .line {
		position: absolute;
		top: 0;
		width: 100%;
		opacity: 0;
		animation: hero-search-fade 15s infinite ease-in-out;
		font-weight: 600;
	}

	& .line:nth-child(1) {
		animation-delay: 0s;
	}

	& .line:nth-child(2) {
		animation-delay: 3s;
	}

	& .line:nth-child(3) {
		animation-delay: 6s;
	}

	& .line:nth-child(4) {
		animation-delay: 9s;
	}

	& .line:nth-child(5) {
		animation-delay: 12s;
	}

	& .form-floating {
		position: relative;
		flex: 1 1 auto;
		width: 1%;
		min-width: 0;

		& > .form-control {
			border: 1px solid rgb(203, 213, 225) !important;
			border-radius: 0.5rem 0rem 0rem 0.5rem;
			color: $gray-800;
			padding: 1rem .75rem;
		}

		& > .form-control:focus, > .form-control:not(:placeholder-shown) {
			padding-top: 1.625rem;
			padding-bottom: 0.625rem;
			border-color: #a19ce5;
			outline: 0;
			box-shadow: 0 0 0 .25rem rgba(67, 56, 202, 0.25);
		}
	}

	@media(min-width: 1200px) {
		& h1 .display-2, .h1 .display-2 {
			height: 4.5rem;
		}
	}
}

@keyframes hero-search-fade {
	0%,3% {
		opacity: 0;
	}

	5%,20% {
		opacity: 1;
	}

	25%,100% {
		opacity: 0;
	}
}