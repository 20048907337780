[class^="icon-"]:before, [class*=" icon-"]:before {
	font-family: "icons";
	font-style: normal;
	font-weight: normal;
	speak: never;
	display: inline-block;
	text-align: center;
	font-feature-settings: normal;
	font-variant: normal;
	text-transform: none;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-bars {
	&:before {
		content: '\e800';
	}
}

.icon-coins {
	&:before {
		content: '\e801';
	}
}

.icon-download {
	&:before {
		content: '\e802';
	}
}

.icon-search {
	&:before {
		content: '\e803';
	}
}

.icon-arrow-right {
	&:before {
		content: '\e804';
	}
}

.icon-arrow-left {
	&:before {
		content: '\e805';
	}
}

.icon-dumbbell {
	&:before {
		content: '\e806';
	}
}

.icon-spa {
	&:before {
		content: '\e807';
	}
}

.icon-heart {
	&:before {
		content: '\e808';
	}
}

.icon-clothes-hanger {
	&:before {
		content: '\e809';
	}
}

.icon-house {
	&:before {
		content: '\e80a';
	}
}

.icon-utensils {
	&:before {
		content: '\e80b';
	}
}

.icon-briefcase {
	&:before {
		content: '\e80c';
	}
}

.icon-cart-shopping {
	&:before {
		content: '\e814';
	}
}

.icon-table-layout {
	&:before {
		content: '\e80e';
	}
}

.icon-dimensions {
	&:before {
		content: '\e80f';
	}
}

.icon-file-download {
	&:before {
		content: '\e810';
	}
}

.icon-eye {
	&:before {
		content: '\e811';
	}
}

.icon-pen-nib {
	&:before {
		content: '\e812';
	}
}

.icon-pen-paintbrush {
	&:before {
		content: '\e813';
	}
}

.icon-circle-user {
	&:before {
		content: '\e80d';
	}
}

.icon-xmark {
	&:before {
		content: '\e815';
	}
}